import { blogData } from '../mock/blog';


import React, { useState, useEffect } from 'react';
import BlogRoll from '/src/components/Blog/BlogRoll';

import { PortfolioProvider } from '../context/context';
import BlogHero from '../components/BlogHero/BlogHero';
import Tags from '../components/Tags/Tags';


function BlogPage() {
        const [hero, setHero] = useState({});


        useEffect(() => {
            setHero({ ...blogData });

        }, []);


    return (
        <PortfolioProvider value={{ hero }}>
            <BlogHero />
            <Tags />
      <BlogRoll />
    </PortfolioProvider>
       
    );
}


export default BlogPage