import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery , Link} from 'gatsby'
import PreviewCompatibleImage from '/src/components/PreviewCompatibleImage'
import { CardColumns, Card, Button } from 'react-bootstrap';


class BlogRoll extends React.Component {
    render() {
        const { data } = this.props
        const { edges: posts } = data.allMarkdownRemark

        return (
           
            <section id="blog">
                <CardColumns className="blog-deck" >
                    {posts &&
                        posts.map(({ node: post }) => (
                            <Link to={post.fields.slug} className="">
                        <Card className="blog-cards" bg="light">
                            
                            <PreviewCompatibleImage
                                imageInfo={{
                                    image: post.frontmatter.featuredimage,
                                    alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                }}
                            />
                                <Card.Body>
                                <Card.Title className="blog-cards__title" >{post.frontmatter.title}</Card.Title>
                                <Card.Subtitle className="blog-cards__date mb-2 text-muted">{post.frontmatter.date}</Card.Subtitle>

                                <Card.Text className="blog-cards__text">{post.frontmatter.description}</Card.Text>
                            </Card.Body>
                            </Card>
                        </Link>


                      
                    ))}
                </CardColumns>

                    </section>
        )
    }
}

BlogRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

export default () => (
    <StaticQuery
        query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                description
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
        render={(data, count) => <BlogRoll data={data} count={count} />}
    />
)
