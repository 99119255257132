import React from 'react'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import {  graphql, StaticQuery } from 'gatsby'
import {Container,  Button, Badge } from 'react-bootstrap';

import PropTypes from 'prop-types'


class TagsPage extends React.Component {
    render() {
        const { data } = this.props
        
        const { group: group } = data.allMarkdownRemark


        return (
            <section id="tags">
                <Container className="tag-wrapper">
                <Helmet title="Tags"/>
                <div >
                        <h1 className="tag-wrapper__title is-size-2 is-bold-light">Tags</h1>
                        <ul className="tag-wrapper__taglist">
                            {group.map((tag) => (
                                <Button className="tag-wrapper__button" href={`/tags/${kebabCase(tag.fieldValue)}/`} variant="primary">
                                    {tag.fieldValue} <Badge variant="light">{tag.totalCount}</Badge>
                                    <span className="sr-only"> unread messages</span>
                                    </Button>
                            ))}
                        </ul>
                    </div>
                    </Container>
            </section>
        )
    }
}




TagsPage.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            group: PropTypes.object,
        }),
       
    }),
}

export default () => (
    <StaticQuery
        query={graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`}
        render={(data, count) => <TagsPage data={data} count={count} />}
    />
)